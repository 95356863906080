import React, { useEffect, useState } from "react";

const NewsFilter = ({
  feed,
  setFilter,
  setCurrentPage,
  feedFilter,
  setFeedFilter,
  personalityFilter,
  setPersonalityFilter,
  labelFilter,
  setLabelFilter,
  labels,
}) => {
  const [showArtistFilter, setShowArtistFilter] = useState(false);
  const [artistSearchInput, setArtistSearchInput] = useState("");
  const [filteredArtists, setFilteredArtists] = useState(null);

  useEffect(() => {
    setFilteredArtists(feed);
  }, [feed]);

  const filterArtistList = (searchTerm, key) => {
    setArtistSearchInput(searchTerm);
    if (!searchTerm) {
      setFilter("");
      setCurrentPage(1);
      setFilteredArtists(feed);
      return;
    }
    const filtered = feed.filter((item) => {
      return item?.artist_name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

    setFilteredArtists(filtered);
    if (key === "Enter") {
      setFilter(searchTerm);
      setCurrentPage(1);
    }
  };

  const handleArtistSelection = (selectedItem) => {
    setArtistSearchInput(selectedItem.artist_name);
    setFilter(selectedItem.artist_name);
    setCurrentPage(1);
    setShowArtistFilter(false);
  };

  const clearArtistSelection = (e) => {
    e.preventDefault();
    setArtistSearchInput("");
    setFilter("");
    setCurrentPage(1);
    setShowArtistFilter(false);
  };

  const handleArtistFeedFilter = (value) => {
    setFeedFilter(value);
    setCurrentPage(1);
  };
  const handleArtistPersonalityFilter = (value) => {
    setPersonalityFilter(value);
    setCurrentPage(1);
  };
  const handleArtistLabelFilter = (value) => {
    setLabelFilter(value);
    setCurrentPage(1);
  };

  if (!feed || !filteredArtists) {
    return <div>Loading feed ...</div>;
  }
  return (
    <div className="sm:flex sm: flex-col sm: items-center sm: justify-center">
      <div className="relative inline-block	my-1 mr-2">
        <label
          htmlFor="artistFilter"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Find Artist:
        </label>
        <button
          className="bg-white rounded-md p-1 w-48 text-left text-sm cursor-pointer border-solid border border-black"
          onClick={() => setShowArtistFilter(!showArtistFilter)}
        >
          {artistSearchInput ? (
            <span className="w-full flex items-center">
              {artistSearchInput}{" "}
              <p
                onClick={(e) => clearArtistSelection(e)}
                className="ml-auto font-bold"
              >
                X
              </p>
            </span>
          ) : (
            "Search Artist"
          )}
        </button>

        <div
          className={`absolute bg-white min-w-230 overflow-y-scroll max-h-80 z-1 border border-grey rounded-md ${
            showArtistFilter ? "block" : "hidden"
          }`}
        >
          <input
            type="text"
            defaultValue={artistSearchInput}
            className="box-border p-2 text-base border-b"
            onKeyUp={(e) => filterArtistList(e.target.value, e.key)}
            placeholder="Search.."
          />
          {filteredArtists.slice(0, 20).map((item) => (
            <h5
              onClick={() => handleArtistSelection(item)}
              className="p-1 text-base bg-gray-100 hover:bg-gray-400 cursor-pointer"
              key={item.rtsvrf_artist_id}
            >
              {item.artist_name}
            </h5>
          ))}
        </div>
      </div>
      <div className="relative inline-block	my-1 mr-2">
        <label
          htmlFor="feedFilter"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Feed Type:
        </label>
        <select
          defaultValue={feedFilter}
          onChange={(e) => handleArtistFeedFilter(e.target.value)}
          className="bg-white rounded-md p-[5px] w-48 text-left text-sm cursor-pointer border-solid border border-black"
        >
          <option value={""}>All</option>
          <option value={"Feed"}>Select artists with feeds</option>
          <option value={"No feed"}>Select artists with no feeds</option>
        </select>
      </div>
      <div className="relative inline-block	my-1 mr-2">
        <label
          htmlFor="personalityFilter"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Personality Type:
        </label>
        <select
          defaultValue={personalityFilter}
          onChange={(e) => handleArtistPersonalityFilter(e.target.value)}
          className="bg-white rounded-md p-[5px] w-48 text-left text-sm cursor-pointer border-solid border border-black"
        >
          <option value={"All"}>All</option>
          <option value={null}>Musician</option>
          <option value={"Others"}>Others</option>
        </select>
      </div>
      <div className="relative inline-block	my-1 mr-2">
        <label
          htmlFor="labelFilter"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Group:
        </label>
        <select
          defaultValue={labelFilter}
          onChange={(e) => handleArtistLabelFilter(e.target.value)}
          className="bg-white rounded-md p-[5px] w-48 text-left text-sm cursor-pointer border-solid border border-black"
        >
          <option value={""}>Select a Group</option>
          {labels?.length &&
            labels.map((label) => (
              <option value={label.rtsvrf_label_code}>
                {label.rtsvrf_label_name}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default NewsFilter;
