import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import logo from "./rtsvrf-assets/avmainlogo.png";
import footerlogo from "./rtsvrf-assets/avfooter.jpg";
import ReactGA from "react-ga4";
import "./App.css";
import Profile from "./rtsvrf-pages/Profile";
import ArtistList from "./rtsvrf-pages/ArtistList";
import { getArtistIdByVanityName } from "./rtsvrf-actions/Actions";
import Loading from "./rtsvrf-components/Loading";

const App = () => {
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const layout = searchParams.get("layout");
  useEffect(() => {
    ReactGA.initialize("G-KFT074EKXZ", {
      gaOptions: { streamId: "5114365372" },
    });
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
    console.log(searchParams, "searchParams");
  }, [location]);

  const RedirectArtist = () => {
    const { name } = useParams();
    const [artistId, setArtistId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchArtistId = async () => {
        const id = await getArtistIdByVanityName(name);
        if (id) setArtistId(id);
        setLoading(false);
      };

      fetchArtistId();
    }, [name, artistId]);

    if (loading) {
      // Handle loading state
      return <Loading />;
    } else if (!artistId && !loading) {
      // Handle invalid artist name
      return <Navigate to={`/`} replace />;
    } else {
      return (
        <Navigate
          to={`/artist/${artistId}${layout === "v2" ? "?layout=v2" : ""}`}
          replace
        />
      );
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <table>
          <tr>
            <td>
              <h1>ArtistVerified Artist Profile - Viewer App</h1>
            </td>
            <td>
              <img
                src={logo}
                width={"60"}
                height={"60"}
                className="App-logo"
                alt="logo"
                align="right"
              />
            </td>
          </tr>
        </table>
      </header>
      <Routes>
        <Route exact path="/:name" element={<RedirectArtist />} />
        <Route path="/" element={<ArtistList />} />
        <Route path="/artist/:id" element={<Profile />} />
      </Routes>

      <center className="my-4">
        <img src={footerlogo} className="App-logo" alt="footerlogo" />
        <br />
        Copyright &copy; 2023 ArtistVerified Inc.
      </center>
    </div>
  );
};

export default App;
