import React from "react";

const Error = ({ title, subtext, errorCode, children }) => {
  return (
    <div className="w-full h-96 mt-12 flex justify-center items-start">
      <div class="flex flex-col items-center">
        <div class="text-indigo-500 font-bold text-7xl">
          {errorCode || "404"}
        </div>

        <div class="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
          {title || "This page does not exist"}
        </div>

        {children ? (
          children
        ) : (
          <div class="text-gray-800 font-medium text-sm md:text-xl lg:text-2xl mt-8">
            {subtext || "The page you are looking for could not be found."}
          </div>
        )}
      </div>
    </div>
  );
};

export default Error;
