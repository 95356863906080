import { supabase } from "../rtsvrf-database/SupabaseClient";

export const getArtistIdByVanityName = async (name) => {
  let results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .eq("artist_vanity_name", name)
    .eq("rtsvrf_profile_claim_status", true)
    .single();

  return results?.data?.rtsvrf_artist_id;
};

export const getArtistEditableFields = async (artistId) => {
  if (!artistId) return;
  const results = await supabase
    .from("tbl_locked_artist_editable_fields")
    .select()
    .eq("rtsvrf_artist_id", artistId)
    .single();
  return results.data;
};

export const getArtistPreferences = async (artistId) => {
  if (!artistId) return;
  const results = await supabase
    .from("tbl_staged_artist_profile_preferences")
    .select()
    .eq("rtsvrf_artist_id", artistId)
    .single();
  return results.data;
};

export const getArtistLabels = async () => {
  const results = await supabase.from("tbl_locked_labels").select();
  return results.data ?? [];
};

export const getArtistProfile2Preferences = async (artistId) => {
  if (!artistId) return;
  const { data, error } = await supabase
    .from("tbl_locked_artist_profile_v2_preferences")
    .select()
    .eq("rtsvrf_artist_id", artistId)
    .single();

  return data ?? null;
};
