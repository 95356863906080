import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { supabase } from "../rtsvrf-database/SupabaseClient";
import Loading from "../rtsvrf-components/Loading";
import Error from "../rtsvrf-components/Error";
import share from "../rtsvrf-assets/icons/sharing.png";
import { fieldMappings, platformMappings } from "../rtsvrf-utils/FieldMappings";
import {
  getArtistEditableFields,
  getArtistProfile2Preferences,
} from "../rtsvrf-actions/Actions";
import ProfileV1 from "../rtsvrf-components/ProfileV1";
import ProfileV2 from "../rtsvrf-components/ProfileV2";

const Profile = () => {
  const [artist, setArtist] = useState();
  const [preferences, setPreferences] = useState({});
  const [platformLiveLinks, setPlatformLiveLinks] = useState({});
  const [slug, setSlug] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  const [liveLinks, setLiveLinks] = useState([]);
  const [fieldsStatus, setFieldsStatus] = useState({});
  const [artistProfile2Preferences, setArtistProfile2Preferences] = useState();
  const [isFieldStatusAvailable, setIsFieldStatusAvailable] = useState(false);
  const [artistImage, setArtistImage] = useState();
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const layout = searchParams.get("layout");
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let results = await supabase
        .from("tbl_locked_artist_ids_stub_v2")
        .select()
        .eq("rtsvrf_artist_id", id)
        .single();

      setArtist(
        results.data
          ? {
              ...results.data,
              artist_spotfy_id: results.data?.artist_spotfy_id?.includes(
                "SPOTIFY_UNKNOWN"
              )
                ? null
                : results.data.artist_spotfy_id,
            }
          : null
      );
      if (results.data?.artist_news_link) {
        const xmlLink = results?.data?.artist_news_link?.split(
          "https://rss.app/feeds/"
        )?.[1];
        setSlug(xmlLink?.split(".xml")?.[0]);
      }
    };
    getData();
  }, [id]);

  useEffect(() => {
    const getProfileData = async () => {
      if (!artist || !artist.rtsvrf_artist_id) return;
      let results = await supabase
        .from("tbl_locked_artist_pics")
        .select()
        .eq("rtsvrf_artist_id", artist.rtsvrf_artist_id)
        .single();

      if (results?.data) {
        const artistData = results?.data;
        setArtistImage(artistData[artistData.artist_picture_preference]);
      }
    };
    const getLinksData = async () => {
      let links = await supabase.from("tbl_locked_platform_list").select();
      if (links.data) {
        setLiveLinks(links.data);
        let platformLiveLinkList = {};
        links.data?.forEach((link) => {
          if (link.platform_link_stub_artist) {
            const field = platformMappings[link.platform_code];
            let value = link.platform_link_stub_artist;
            if (value && field) {
              value = value.replace("$USER", artist?.[field]);
              platformLiveLinkList[field] = value;
            }
          }
        });
        setPlatformLiveLinks(platformLiveLinkList);
      }
    };
    getProfileData();
    getLinksData();
  }, [id, artist]);

  useEffect(() => {
    const getArtistPreferencesAndStatus = async () => {
      if (!artist) return;
      const fields = await getArtistEditableFields(artist?.rtsvrf_artist_id);
      console.log(fields, "fields");
      if (fields) {
        setFieldsStatus(fields);
        setIsFieldStatusAvailable(true);
      } else {
        setFieldsStatus({});
        setIsFieldStatusAvailable(false);
      }
      let preferencesLinks = {};

      let preferencesList = await supabase
        .from("tbl_staged_artist_profile_preferences")
        .select()
        .eq("rtsvrf_artist_id", artist.rtsvrf_artist_id)
        .single();

      if (preferencesList?.data) {
        const preferencesData = preferencesList.data;
        for (const key in preferencesData) {
          if (preferencesData[key]) {
            let value = liveLinks?.find(
              (link) =>
                link.platform_code === preferencesData[key] &&
                link.platform_link_stub_artist
            )?.platform_link_stub_artist;
            const field = fieldMappings[preferencesData[key]];
            if (value && field) {
              console.log(value);
              value = value.replace("$ARTIST", artist[field]);
              preferencesLinks[key] = value;
            }
          }
        }
        setPreferences(preferencesLinks);
      }
      setLoading(false);
      console.log(layout, "layout");
      if (layout === "v2") {
        const profileV2preferences = await getArtistProfile2Preferences(
          artist.rtsvrf_artist_id
        );
        if (profileV2preferences) {
          setArtistProfile2Preferences(profileV2preferences);
        } else {
          setArtistProfile2Preferences({
            rtsvrf_artist_id: artist.rtsvrf_artist_id,
            artist_image: true,
            link_icons_list: [],
            artist_news: true,
            social_stream_embed1: null,
            social_stream_embed2: null,
            merch_offer: null,
            tickets_offer: null,
          });
        }
      }
    };

    getArtistPreferencesAndStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, artist, liveLinks]);

  const goBack = () => {
    navigate("/");
  };

  return (
    <div className="bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col">
      <nav className="w-full flex justify-between rounded-md px-4">
        <ol className="list-reset flex pt-2">
          <li key={1} className="cursor-pointer" onClick={() => goBack()}>
            <p className="text-white transition duration-150 ease-in-out">
              App
            </p>
          </li>
          <li key={2}>
            <span className="mx-2 text-black">/</span>
          </li>
          <li key={3} className="text-black">
            Profile
          </li>
          <li key={4}>
            <span className="mx-2 text-black">/</span>
          </li>
          <li key={5} className="text-black">
            {artist?.artist_name}
          </li>
        </ol>
        <div></div>
      </nav>
      {loading ? (
        <Loading />
      ) : !artist || fieldsStatus?.published === false ? (
        <Error
          title={fieldsStatus?.published === false && "Profile Unavailable"}
          errorCode={"204"}
        >
          <p>
            This artist profile is temporarily unavailable. For any inquiries,
            contact{" "}
            <a
              target="_blank"
              href={`mailto:support@artistverified.com?subject=AV%20Artist%20profile%20not%20active:%20${id}`}
              rel="noreferrer"
            >
              support@artistverified.com
            </a>
          </p>
        </Error>
      ) : (
        <>
          {layout === "v2" ? (
            <ProfileV2
              artist={artist}
              loading={loading}
              fieldsStatus={fieldsStatus}
              artistImage={artistImage}
              isFieldStatusAvailable={isFieldStatusAvailable}
              artistProfile2Preferences={artistProfile2Preferences}
              expand={expand}
              setExpand={setExpand}
              id={id}
              goBack={goBack}
              preferences={preferences}
              slug={slug}
              platformLiveLinks={platformLiveLinks}
            />
          ) : (
            <ProfileV1
              artist={artist}
              loading={loading}
              fieldsStatus={fieldsStatus}
              artistImage={artistImage}
              isFieldStatusAvailable={isFieldStatusAvailable}
              expand={expand}
              setExpand={setExpand}
              id={id}
              goBack={goBack}
              preferences={preferences}
              slug={slug}
              platformLiveLinks={platformLiveLinks}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
