import React, { useEffect, useRef, useState } from "react";
import {
  HUBSPOT_FORM,
  NEWS_LINK,
  modalCustomStyles,
} from "../rtsvrf-utils/Constants";
import YouTube from "react-youtube";
import share from "../rtsvrf-assets/icons/sharing_white.png";
import Modal from "react-modal";
import CloseIcon from "../rtsvrf-assets/icons/close.png";
import website from "../rtsvrf-assets/icons/web_white.png";
import twitter from "../rtsvrf-assets/icons/x_white.png";
import facebook from "../rtsvrf-assets/icons/facebook_white.png";
import instagram from "../rtsvrf-assets/icons/insta_white.png";
import youtube from "../rtsvrf-assets/icons/youtube_white.png";
import tiktok from "../rtsvrf-assets/icons/tiktok_white.png";
import spotify from "../rtsvrf-assets/icons/spotify_white.png";
import apple from "../rtsvrf-assets/icons/apple_white.png";
import deezer from "../rtsvrf-assets/icons/deezer_white.png";
import amazon from "../rtsvrf-assets/icons/amazon_white.png";
import tickets from "../rtsvrf-assets/icons/tickets_white.png";
import threads from "../rtsvrf-assets/icons/threads_white.png";
import snapchat from "../rtsvrf-assets/icons/snapchat_white.png";
import mastodon from "../rtsvrf-assets/icons/mastodon_white.png";
import bluesky from "../rtsvrf-assets/icons/bluesky_white.png";
import signal from "../rtsvrf-assets/icons/signal_white.png";
import whatsapp from "../rtsvrf-assets/icons/whatsapp_white.png";
import telegram from "../rtsvrf-assets/icons/telegram_white.png";
import discord from "../rtsvrf-assets/icons/discord_white.png";
import twitch from "../rtsvrf-assets/icons/twitch_white.png";
import vimeo from "../rtsvrf-assets/icons/vimeo_white.png";
import tidal from "../rtsvrf-assets/icons/tidal_white.png";
import audius from "../rtsvrf-assets/icons/audius_white.png";
import sound from "../rtsvrf-assets/icons/sound_white.png";
import soundcloud from "../rtsvrf-assets/icons/soundcloud_white.png";

import merch from "../rtsvrf-assets/icons/merch_white.png";
import disabledmerch from "../rtsvrf-assets/icons/merch_disabled.png";
import disabledwebsite from "../rtsvrf-assets/icons/web_disabled.png";
import disabledtwitter from "../rtsvrf-assets/icons/x_disabled.png";
import disabledvimeo from "../rtsvrf-assets/icons/vimeo_disabled.png";
import disabledtidal from "../rtsvrf-assets/icons/tidal_disabled.png";
import disabledaudius from "../rtsvrf-assets/icons/audius_disabled.png";
import disabledsound from "../rtsvrf-assets/icons/sound_disabled.png";
import disabledsoundcloud from "../rtsvrf-assets/icons/soundcloud_disabled.png";
import disabledtwitch from "../rtsvrf-assets/icons/twitch_disabled.png";
import disabledfacebook from "../rtsvrf-assets/icons/facebook_disabled.png";
import disabledinstagram from "../rtsvrf-assets/icons/instagram_disabled.png";
import disabledyoutube from "../rtsvrf-assets/icons/youtube_disabled.png";
import disabledtiktok from "../rtsvrf-assets/icons/tiktok_disabled.png";
import disabledspotify from "../rtsvrf-assets/icons/spotify_disabled.png";
import disabledapple from "../rtsvrf-assets/icons/apple_disabled.png";
import disableddeezer from "../rtsvrf-assets/icons/deezer_disabled.png";
import disabledamazon from "../rtsvrf-assets/icons/amazon-disabled.png";
import disabledtickets from "../rtsvrf-assets/icons/diabled-tickets.png";
import disabledthreads from "../rtsvrf-assets/icons/threads_disabled.png";
import disabledsnapchat from "../rtsvrf-assets/icons/snapchat_disabled.png";
import disabledmastodon from "../rtsvrf-assets/icons/mastodon_disabled.png";
import disabledbluesky from "../rtsvrf-assets/icons/bluesky_disabled.png";
import disabledsignal from "../rtsvrf-assets/icons/signal_disabled.png";
import disabledwhatsapp from "../rtsvrf-assets/icons/whatsapp_disabled.png";
import disabledtelegram from "../rtsvrf-assets/icons/telegram_disabled.png";
import disableddiscord from "../rtsvrf-assets/icons/discord_disabled.png";
import disablednews from "../rtsvrf-assets/icons/news_disabled.png";
import community from "../rtsvrf-assets/icons/community_white.png";
import disabledcommunity from "../rtsvrf-assets/icons/disabled-community.png";
import verified from "../rtsvrf-assets/icons/av-tick.png";
import disabledspotlight from "../rtsvrf-assets/icons/disabled-show.png";
import spotlight from "../rtsvrf-assets/icons/show_white.png";
import google from "../rtsvrf-assets/icons/play_store.png";
import appleStore from "../rtsvrf-assets/icons/apple_store.svg";
import claim from "../rtsvrf-assets/icons/grab.png";
import defaultImage from "../rtsvrf-assets/icons/account.png";
import logo from "../rtsvrf-assets/ArtistVerified.png";
import Loading from "./Loading";
import CopyToClipboard from "react-copy-to-clipboard";

Modal.setAppElement("#root");
const ProfileV2 = ({
  artist,
  loading,
  fieldsStatus,
  artistImage,
  isFieldStatusAvailable,
  artistProfile2Preferences,
  expand,
  setExpand,
  id,
  goBack,
  preferences,
  slug,
  platformLiveLinks,
}) => {
  const [singlePlatform, setSinglePlatform] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const url = window.location.origin + window.location.pathname;
  useEffect(() => {
    if (
      artistProfile2Preferences &&
      artistProfile2Preferences?.social_stream_embed1 &&
      artistProfile2Preferences?.social_stream_embed2
    ) {
      setSinglePlatform(false);
    }
  }, [artistProfile2Preferences]);

  if (!artistProfile2Preferences) {
    return <Loading />;
  }
  return (
    <>
      <div className="flex flex-col justify-start items-center mt-6">
        <div className="bg-avRed w-full py-4 flex justify-between items-center">
          <div className="flex">
            <h1 className="text-4xl ml-4 font-bold text-white">
              {artist?.artist_name}
            </h1>
            {artist?.rtsvrf_profile_claim_status ? (
              <span className="ml-4">
                <img width={"30"} height={"30"} alt="verified" src={logo} />
              </span>
            ) : (
              <span
                className="cursor-pointer ml-4"
                onClick={() => (window.location.href = HUBSPOT_FORM)}
              >
                <img width={"30"} height={"30"} alt="claim" src={claim} />
              </span>
            )}
          </div>
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            className="mr-2"
          >
            <img className="w-10 h-10" src={share} alt="icon" />
          </button>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="flex flex-col justify-start items-center">
          <img
            // className={`${artistImage ? "w-80 h-80" : "w-48 h-48"} rounded-lg`}
            src={artistImage || defaultImage}
            alt="profile-img"
          />
        </div>

        <div className="bg-avRed w-full flex justify-around items-start">
          {artistProfile2Preferences?.link_icons_list?.length ? (
            <>
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_website_link"
              ) && (
                <button
                  disabled={
                    !artist?.artist_website_link ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.website_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_website_link &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.website_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = artist?.artist_website_link)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_website_link &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.website_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? website
                        : disabledwebsite
                    }
                    alt="icon"
                  />
                </button>
              )}

              {artistProfile2Preferences.link_icons_list.includes(
                "artist_facebk_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_facebk_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.facebk_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_facebk_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.facebk_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://www.facebook.com/${artist?.artist_facebk_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_facebk_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.facebk_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? facebook
                        : disabledfacebook
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_youtub_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_youtub_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.youtube_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_youtub_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.youtube_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://www.youtube.com/user/${artist?.artist_youtub_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_youtub_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.youtube_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? youtube
                        : disabledyoutube
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_spotfy_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_spotfy_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.spotify_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_spotfy_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.spotify_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://open.spotify.com/artist/${artist?.artist_spotfy_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_spotfy_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.spotify_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? spotify
                        : disabledspotify
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_twitch_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_twitch_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.twitch_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_twitch_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.twitch_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://www.twitch.tv/${artist?.artist_twitch_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_twitch_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.twitch_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? twitch
                        : disabledtwitch
                    }
                    alt="icon"
                  />
                </button>
              )}

              {artistProfile2Preferences.link_icons_list.includes(
                "artist_vimeo_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_vimeo_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.vimeo_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_vimeo_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.vimeo_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://vimeo.com/${artist?.artist_vimeo_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_vimeo_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.vimeo_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? vimeo
                        : disabledvimeo
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_tidal_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_tidal_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.tidal_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_tidal_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.tidal_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://tidal.com/browse/artist/${artist?.artist_tidal_id}`)
                  }
                  className="sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-[85px] h-[85px] sm:w-12 sm:h-12"
                    src={
                      artist?.artist_tidal_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.tidal_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? tidal
                        : disabledtidal
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_audius_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_audius_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.audius_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_audius_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.audius_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://audius.co/${artist?.artist_audius_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_audius_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.audius_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? audius
                        : disabledaudius
                    }
                    style={
                      artist?.artist_audius_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.audius_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? { borderRadius: "50%", border: "5px solid white" }
                        : { borderRadius: "50%", border: "5px solid #A39E9E" }
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_sound_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_sound_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.sound_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_sound_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.sound_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://www.sound.xyz/${artist?.artist_sound_id}`)
                  }
                  className="mx-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-[130px] h-[80px] sm:w-12 sm:h-12"
                    src={
                      artist?.artist_sound_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.sound_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? sound
                        : disabledsound
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_sound_cloud_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_sound_cloud_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.sound_cloud_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_sound_cloud_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.sound_cloud_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://soundcloud.com/${artist?.artist_sound_cloud_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_sound_cloud_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.sound_cloud_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? soundcloud
                        : disabledsoundcloud
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "tickets_platform_link"
              ) && (
                <button
                  disabled={
                    (!artist?.artist_bandsit_id &&
                      !preferences.artist_platform_preference_tickets &&
                      !artist?.artist_other_tickets_platform_link) ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.tickets_platform_link_status === false)
                  }
                  onClick={() =>
                    (artist?.artist_bandsit_id ||
                      preferences.artist_platform_preference_tickets ||
                      artist?.artist_other_tickets_platform_link) &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.tickets_platform_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      preferences.artist_platform_preference_tickets ||
                      artist?.artist_other_tickets_platform_link ||
                      `https://www.bandsintown.com/a/${artist?.artist_bandsit_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      (artist?.artist_bandsit_id ||
                        preferences.artist_platform_preference_tickets ||
                        artist?.artist_other_tickets_platform_link) &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.tickets_platform_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? tickets
                        : disabledtickets
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_aplmus_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_aplmus_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.aplmus_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_aplmus_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.aplmus_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://music.apple.com/us/artist/${artist?.artist_aplmus_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_aplmus_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.aplmus_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? apple
                        : disabledapple
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "merch_platform_link"
              ) && (
                <button
                  disabled={
                    (!artist?.artist_mrchbr_id &&
                      !preferences.artist_platform_preference_merch &&
                      !artist?.artist_other_merch_platform_link) ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.merchandise_platform_link_status === false)
                  }
                  onClick={() =>
                    (artist?.artist_mrchbr_id ||
                      preferences.artist_platform_preference_merch ||
                      artist?.artist_other_merch_platform_link) &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.merchandise_platform_link_status !==
                        false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      preferences.artist_platform_preference_merch ||
                      artist?.artist_other_merch_platform_link ||
                      artist?.artist_mrchbr_id)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      (artist?.artist_mrchbr_id ||
                        preferences.artist_platform_preference_merch ||
                        artist?.artist_other_merch_platform_link) &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.merchandise_platform_link_status !==
                          false) ||
                        !isFieldStatusAvailable)
                        ? merch
                        : disabledmerch
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_twitter_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_twitter_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.twitter_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_twitter_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.twitter_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://twitter.com/${artist?.artist_twitter_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_twitter_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.twitter_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? twitter
                        : disabledtwitter
                    }
                    alt="icon"
                  />
                </button>
              )}

              {artistProfile2Preferences.link_icons_list.includes(
                "artist_threads_link"
              ) && (
                <button
                  disabled={
                    !artist?.artist_threads_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.threads_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_threads_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.threads_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      platformLiveLinks?.artist_threads_id)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-[82px] h-[70px] sm:w-16 sm:h-[55px]"
                    src={
                      artist?.artist_threads_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.threads_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? threads
                        : disabledthreads
                    }
                    alt="icon"
                  />
                </button>
              )}

              {artistProfile2Preferences.link_icons_list.includes(
                "artist_snapchat_link"
              ) && (
                <button
                  disabled={
                    !artist?.artist_snapchat_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.snapchat_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_snapchat_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.snapchat_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      platformLiveLinks?.artist_snapchat_id)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_snapchat_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.snapchat_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? snapchat
                        : disabledsnapchat
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_mastodon_link"
              ) && (
                <button
                  disabled={
                    !artist?.artist_mastodon_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.mastodon_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_mastodon_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.mastodon_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      platformLiveLinks?.artist_mastodon_id)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_mastodon_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.mastodon_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? mastodon
                        : disabledmastodon
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_bluesky_link"
              ) && (
                <button
                  disabled={
                    !artist?.artist_bluesky_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.bluesky_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_bluesky_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.bluesky_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      platformLiveLinks?.artist_bluesky_id)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_bluesky_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.bluesky_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? bluesky
                        : disabledbluesky
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_discord_link"
              ) && (
                <button
                  disabled={
                    !artist?.artist_discord_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.discord_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_discord_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.discord_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      platformLiveLinks?.artist_discord_id)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_discord_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.discord_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? discord
                        : disableddiscord
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_signal_link"
              ) && (
                <button
                  disabled={
                    !artist?.artist_signal_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.signal_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_signal_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.signal_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = platformLiveLinks?.artist_signal_id)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_signal_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.signal_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? signal
                        : disabledsignal
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_whatsapp_link"
              ) && (
                <button
                  disabled={
                    !artist?.artist_whatsapp_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.whatsapp_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_whatsapp_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.whatsapp_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      platformLiveLinks?.artist_whatsapp_id)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_whatsapp_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.whatsapp_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? whatsapp
                        : disabledwhatsapp
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_telegram_link"
              ) && (
                <button
                  disabled={
                    !artist?.artist_telegram_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.telegram_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_telegram_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.telegram_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      platformLiveLinks?.artist_telegram_id)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_telegram_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.telegram_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? telegram
                        : disabledtelegram
                    }
                    alt="icon"
                  />
                </button>
              )}

              {artistProfile2Preferences.link_icons_list.includes(
                "artist_deezer_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_deezer_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.deezer_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_deezer_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.deezer_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://www.deezer.com/en/artist/${artist?.artist_deezer_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_deezer_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.deezer_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? deezer
                        : disableddeezer
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "shows_platform_link"
              ) && (
                <button
                  disabled={
                    (!preferences?.artist_platform_preference_shows &&
                      !artist?.artist_other_shows_platform_link) ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.shows_platform_link_status === false)
                  }
                  onClick={() =>
                    (preferences?.artist_platform_preference_shows ||
                      artist?.artist_other_shows_platform_link) &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.shows_platform_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      preferences?.artist_platform_preference_shows ||
                      artist?.artist_other_shows_platform_link)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      preferences?.artist_platform_preference_shows ||
                      artist?.artist_other_shows_platform_link
                        ? spotlight
                        : disabledspotlight
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_instag_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_instag_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.instag_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_instag_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.instag_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://instagram.com/${artist?.artist_instag_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_instag_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.instag_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? instagram
                        : disabledinstagram
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_amazon_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_amazon_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.amazon_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_amazon_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.amazon_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://www.amazon.com/music/player/artists/${artist?.artist_amazon_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_amazon_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.amazon_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? amazon
                        : disabledamazon
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "community_platform_link"
              ) && (
                <button
                  disabled={
                    (!preferences?.artist_platform_preference_community &&
                      !artist?.artist_other_community_platform_link &&
                      !artist?.artist_discrd_link &&
                      !artist?.artist_patren_id) ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.community_platform_link_status === false)
                  }
                  onClick={() =>
                    (preferences?.artist_platform_preference_community ||
                      artist?.artist_other_community_platform_link ||
                      artist?.artist_discrd_link ||
                      artist?.artist_patren_id) &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.community_platform_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href =
                      preferences?.artist_platform_preference_community ||
                      artist?.artist_other_community_platform_link ||
                      artist?.artist_discrd_link ||
                      artist?.artist_patren_id)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      (preferences?.artist_platform_preference_community ||
                        artist?.artist_other_community_platform_link ||
                        artist?.artist_discrd_link ||
                        artist?.artist_patren_id) &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.community_platform_link_status !==
                          false) ||
                        !isFieldStatusAvailable)
                        ? community
                        : disabledcommunity
                    }
                    alt="icon"
                  />
                </button>
              )}
              {artistProfile2Preferences.link_icons_list.includes(
                "artist_tiktok_id"
              ) && (
                <button
                  disabled={
                    !artist?.artist_tiktok_id ||
                    (isFieldStatusAvailable &&
                      fieldsStatus?.tiktok_link_status === false)
                  }
                  onClick={() =>
                    artist?.artist_tiktok_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.tiktok_link_status !== false) ||
                      !isFieldStatusAvailable) &&
                    (window.location.href = `https://www.tiktok.com/@${artist?.artist_tiktok_id}`)
                  }
                  className="m-2 sm:mx-0 sm:my-2"
                >
                  <img
                    className="w-16 h-16 sm:w-12 sm:h-12"
                    src={
                      artist?.artist_tiktok_id &&
                      ((isFieldStatusAvailable &&
                        fieldsStatus?.tiktok_link_status !== false) ||
                        !isFieldStatusAvailable)
                        ? tiktok
                        : disabledtiktok
                    }
                    alt="icon"
                  />
                </button>
              )}
            </>
          ) : (
            <>
              <button
                disabled={
                  !artist?.artist_website_link ||
                  (isFieldStatusAvailable &&
                    fieldsStatus?.website_link_status === false)
                }
                onClick={() =>
                  artist?.artist_website_link &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.website_link_status !== false) ||
                    !isFieldStatusAvailable) &&
                  (window.location.href = artist?.artist_website_link)
                }
                className="m-2 sm:mx-0 sm:my-2"
              >
                <img
                  className="w-16 h-16 sm:w-12 sm:h-12"
                  src={
                    artist?.artist_website_link &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.website_link_status !== false) ||
                      !isFieldStatusAvailable)
                      ? website
                      : disabledwebsite
                  }
                  alt="icon"
                />
              </button>

              <button
                disabled={
                  !artist?.artist_facebk_id ||
                  (isFieldStatusAvailable &&
                    fieldsStatus?.facebk_link_status === false)
                }
                onClick={() =>
                  artist?.artist_facebk_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.facebk_link_status !== false) ||
                    !isFieldStatusAvailable) &&
                  (window.location.href = `https://www.facebook.com/${artist?.artist_facebk_id}`)
                }
                className="m-2 sm:mx-0 sm:my-2"
              >
                <img
                  className="w-16 h-16 sm:w-12 sm:h-12"
                  src={
                    artist?.artist_facebk_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.facebk_link_status !== false) ||
                      !isFieldStatusAvailable)
                      ? facebook
                      : disabledfacebook
                  }
                  alt="icon"
                />
              </button>
              <button
                disabled={
                  !artist?.artist_youtub_id ||
                  (isFieldStatusAvailable &&
                    fieldsStatus?.youtube_link_status === false)
                }
                onClick={() =>
                  artist?.artist_youtub_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.youtube_link_status !== false) ||
                    !isFieldStatusAvailable) &&
                  (window.location.href = `https://www.youtube.com/user/${artist?.artist_youtub_id}`)
                }
                className="m-2 sm:mx-0 sm:my-2"
              >
                <img
                  className="w-16 h-16 sm:w-12 sm:h-12"
                  src={
                    artist?.artist_youtub_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.youtube_link_status !== false) ||
                      !isFieldStatusAvailable)
                      ? youtube
                      : disabledyoutube
                  }
                  alt="icon"
                />
              </button>
              <button
                disabled={
                  !artist?.artist_spotfy_id ||
                  (isFieldStatusAvailable &&
                    fieldsStatus?.spotify_link_status === false)
                }
                onClick={() =>
                  artist?.artist_spotfy_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.spotify_link_status !== false) ||
                    !isFieldStatusAvailable) &&
                  (window.location.href = `https://open.spotify.com/artist/${artist?.artist_spotfy_id}`)
                }
                className="m-2 sm:mx-0 sm:my-2"
              >
                <img
                  className="w-16 h-16 sm:w-12 sm:h-12"
                  src={
                    artist?.artist_spotfy_id &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.spotify_link_status !== false) ||
                      !isFieldStatusAvailable)
                      ? spotify
                      : disabledspotify
                  }
                  alt="icon"
                />
              </button>
              <button
                disabled={
                  (!artist?.artist_bandsit_id &&
                    !preferences.artist_platform_preference_tickets &&
                    !artist?.artist_other_tickets_platform_link) ||
                  (isFieldStatusAvailable &&
                    fieldsStatus?.tickets_platform_link_status === false)
                }
                onClick={() =>
                  (artist?.artist_bandsit_id ||
                    preferences.artist_platform_preference_tickets ||
                    artist?.artist_other_tickets_platform_link) &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.tickets_platform_link_status !== false) ||
                    !isFieldStatusAvailable) &&
                  (window.location.href =
                    preferences.artist_platform_preference_tickets ||
                    artist?.artist_other_tickets_platform_link ||
                    `https://www.bandsintown.com/a/${artist?.artist_bandsit_id}`)
                }
                className="m-2 sm:mx-0 sm:my-2"
              >
                <img
                  className="w-16 h-16 sm:w-12 sm:h-12"
                  src={
                    (artist?.artist_bandsit_id ||
                      preferences.artist_platform_preference_tickets ||
                      artist?.artist_other_tickets_platform_link) &&
                    ((isFieldStatusAvailable &&
                      fieldsStatus?.tickets_platform_link_status !== false) ||
                      !isFieldStatusAvailable)
                      ? tickets
                      : disabledtickets
                  }
                  alt="icon"
                />
              </button>
            </>
          )}
        </div>
        {artistProfile2Preferences?.artist_news && (
          <div className="flex justify-center my-2 p-2">
            {slug &&
              (!isFieldStatusAvailable ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.artist_news_link_status !== false)) && (
                <iframe
                  height={600}
                  className="rounded-md w-webkit lg:w-full"
                  title="artist feed"
                  src={`https://rss.app/embed/v1/list/${slug}`}
                  frameborder="0"
                ></iframe>
              )}
          </div>
        )}
      </div>

      <div className="flex sm:flex-col justify-start items-center m-4">
        {artist?.artist_spotfy_id &&
          (artistProfile2Preferences.social_stream_embed1 ===
            "artist_spotfy_id" ||
            artistProfile2Preferences.social_stream_embed2 ===
              "artist_spotfy_id") &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.spotify_link_status !== false)) && (
            <span
              className={`m-4 sm:w-full ${singlePlatform ? "w-full" : "w-1/2"}`}
            >
              <iframe
                className="rounded-md"
                title="spotify"
                src={`https://open.spotify.com/embed/artist/${artist?.artist_spotfy_id}?utm_source=generator`}
                width="100%"
                height="400"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </span>
          )}

        {artist?.artist_aplmus_default_playlist &&
          artist?.artist_aplmus_default_target_type &&
          (artistProfile2Preferences.social_stream_embed1 ===
            "artist_aplmus_id" ||
            artistProfile2Preferences.social_stream_embed2 ===
              "artist_aplmus_id") &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.aplmus_link_status !== false)) && (
            <span
              className={`m-4 sm:w-full ${singlePlatform ? "w-full" : "w-1/2"}`}
            >
              <iframe
                allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                frameborder="0"
                width="100%"
                className="rounded-md"
                height="400"
                title="apple"
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                src={`https://embed.music.apple.com/us/${artist?.artist_aplmus_default_target_type}/${artist?.artist_aplmus_default_playlist}`}
              ></iframe>
            </span>
          )}

        {artist?.artist_amazon_id &&
          (artistProfile2Preferences.social_stream_embed1 ===
            "artist_amazon_id" ||
            artistProfile2Preferences.social_stream_embed2 ===
              "artist_amazon_id") &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.amazon_link_status !== false)) && (
            <span
              className={`m-4 sm:w-full ${singlePlatform ? "w-full" : "w-1/2"}`}
            >
              <iframe
                title="amazon"
                id={`AmazonMusicEmbed${artist?.artist_amazon_id}`}
                src={`https://music.amazon.com/embed/${artist?.artist_amazon_id}`}
                width="100%"
                height="400"
                className="rounded-md"
              ></iframe>
            </span>
          )}
        {artist?.artist_twitch_default_video_chanel &&
          artist?.artist_twitch_default_video_type &&
          (artistProfile2Preferences.social_stream_embed1 ===
            "artist_twitch_id" ||
            artistProfile2Preferences.social_stream_embed2 ===
              "artist_twitch_id") &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.twitch_link_status !== false)) && (
            <span
              className={`m-4 sm:w-full ${singlePlatform ? "w-full" : "w-1/2"}`}
            >
              <iframe
                title="twitch"
                src={`https://player.twitch.tv/?${artist?.artist_twitch_default_video_type}=${artist?.artist_twitch_default_video_chanel}&parent=${window.location.host}&muted=true`}
                width="100%"
                height="400"
                className="rounded-md"
                allowfullscreen
              ></iframe>
            </span>
          )}

        {artist?.artist_vimeo_default_video &&
          (artistProfile2Preferences.social_stream_embed1 ===
            "artist_vimeo_id" ||
            artistProfile2Preferences.social_stream_embed2 ===
              "artist_vimeo_id") &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.vimeo_link_status !== false)) && (
            <span
              className={`m-4 sm:w-full ${singlePlatform ? "w-full" : "w-1/2"}`}
            >
              <iframe
                title="Vimeo Video Player"
                src={`https://player.vimeo.com/video/${artist?.artist_vimeo_default_video}`}
                width="100%"
                height="400"
                className="rounded-md"
                muted
              />
            </span>
          )}

        {artist?.artist_sound_cloud_default_video &&
          (artistProfile2Preferences.social_stream_embed1 ===
            "artist_sound_cloud_id" ||
            artistProfile2Preferences.social_stream_embed2 ===
              "artist_sound_cloud_id") &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.sound_cloud_link_status !== false)) && (
            <span
              className={`m-4 sm:w-full ${singlePlatform ? "w-full" : "w-1/2"}`}
            >
              <iframe
                title="Soundcloud Video Player"
                src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${artist?.artist_sound_cloud_default_video}&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true`}
                width="100%"
                height="400"
                className="rounded-md"
                muted
              />
            </span>
          )}
        {artist?.artist_tiktok_id &&
          (artistProfile2Preferences.social_stream_embed1 ===
            "artist_tiktok_id" ||
            artistProfile2Preferences.social_stream_embed2 ===
              "artist_tiktok_id") &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.tiktok_link_status !== false)) && (
            <span
              className={`m-4 sm:w-full ${singlePlatform ? "w-full" : "w-1/2"}`}
            >
              <iframe
                src={`https://www.tiktok.com/embed/@${artist?.artist_tiktok_id}`}
                allowfullscreen
                title="tiktok"
                scrolling="no"
                width="100%"
                height="400"
                className="rounded-md"
                allow="encrypted-media;"
              ></iframe>
            </span>
          )}
        {artist?.artist_youtube_default_video &&
          (artistProfile2Preferences.social_stream_embed1 ===
            "artist_youtub_id" ||
            artistProfile2Preferences.social_stream_embed2 ===
              "artist_youtub_id") &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.youtube_link_status !== false)) && (
            <span
              className={`m-4 sm:w-full ${singlePlatform ? "w-full" : "w-1/2"}`}
            >
              <YouTube
                videoId={artist?.artist_youtube_default_video}
                opts={{
                  height: "400",
                  width: "100%",
                  playerVars: {
                    autoplay: 1,
                  },
                }}
                iframeClassName="rounded-md"
                onReady={(e) => e.target.pauseVideo()}
              />
            </span>
          )}
      </div>
      <div className="m-4 flex sm:justify-around justify-center items-center">
        <button className="m-2 cursor-pointer sm:mx-0 sm:my-2">
          <img
            className="cursor-pointer"
            width={"180"}
            height={"80"}
            src={google}
            alt="icon"
          />
        </button>

        <button className="m-2 cursor-pointer sm:ml-2 sm:my-2">
          <img
            className="cursor-pointer"
            width={"180"}
            height={"80"}
            src={appleStore}
            alt="icon"
          />
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={modalCustomStyles}
        contentLabel="Profile Image Modal"
      >
        <div className="flex justify-between">
          <h2 className="text-black text-lg">Update profile picture</h2>
          <span onClick={() => setIsOpen(false)} className="cursor-pointer">
            <img src={CloseIcon} alt="close" width={"15"} height={"15"} />
          </span>
        </div>
        <div className="flex flex-col p-1 w-full text-black items-center">
          <input
            className="w-full p-2 border-black rounded-lg border-2"
            value={url}
            disabled
          />

          <CopyToClipboard text={url} onCopy={() => setIsOpen(false)}>
            <button className="mt-2 p-3 text-black bg-yellow-400 rounded-lg">
              Copy to clipboard
            </button>
          </CopyToClipboard>
        </div>
      </Modal>
    </>
  );
};

export default ProfileV2;
