import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const NewsFeedTable = ({ feed }) => {
  const [mobile, setMobile] = useState(window.innerWidth < 640);

  let navigate = useNavigate();

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const viewProfile = async (artist) => {
    ReactGA.event({
      category: "View Profile",
      action: "click",
      label: "View Profile",
    });
    if (artist?.rtsvrf_artist_id) {
      navigate(`/artist/${artist.rtsvrf_artist_id}`);
    } else {
      alert("Sorry, No data available!");
    }
  };

  if (!feed) {
    return <div>Loading...</div>;
  }
  if (feed.length === 0) {
    return <div>No data found</div>;
  }
  return (
    <div className="my-4 overflow-x-auto">
      <table className="w-full border border-solid border-gray rounded-lg divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
              Artist
            </th>
            {!mobile && (
              <>
                <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                  Genre
                </th>
                <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                  Type
                </th>
              </>
            )}
            <th className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase ">
              Profile
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {feed.map((item) => (
            <tr key={item.rtsvrf_artist_id}>
              <td
                onClick={() => viewProfile(item)}
                className="sm:underline sm:decoration-red-500 sm:underline-offset-4 sm:cursor-pointer px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap"
              >
                {item.artist_name}
              </td>
              {!mobile && (
                <>
                  {" "}
                  <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                    {item.artist_genre}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                    {item.personality_type || "musician"}
                  </td>
                </>
              )}
              <td className="px-6 py-4 text-sm font-medium cursor-pointer text-blue-600 whitespace-nowrap hover:underline">
                <span onClick={() => viewProfile(item)}>View Profile</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewsFeedTable;
