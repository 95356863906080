export const fieldMappings = {
  BNDSIT: "artist_bandsit_id",
  PATREN: "artist_patren_id",
  MRCHBR: "artist_mrchbr_id",
  DISCRD: "artist_discrd_link",
};

export const platformMappings = {
  THREDS: "artist_threads_id",
  SNAPCH: "artist_snapchat_id",
  MASTDN: "artist_mastodon_id",
  BLUSKY: "artist_bluesky_id",
  DISCRD: "artist_discord_id",
  SIGNAL: "artist_signal_id",
  WHTSPP: "artist_whatsapp_id",
  TELGRM: "artist_telegram_id",
};
