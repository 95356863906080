import React, { useState, useEffect } from "react";
import { supabase } from "../rtsvrf-database/SupabaseClient";
import NewsFilter from "../rtsvrf-components/NewsFilter";
import NewsFeedTable from "../rtsvrf-components/NewsFeedTable";
import Pagination from "../rtsvrf-components/Pagination";
import { getArtistLabels } from "../rtsvrf-actions/Actions";

const ArtistList = () => {
  const [feed, setFeed] = useState(null);
  const [feedFilter, setFeedFilter] = useState("");
  const [personalityFilter, setPersonalityFilter] = useState("All");
  const [labelFilter, setLabelFilter] = useState(null);
  const [total, setTotal] = useState(0);
  const [filteredFeed, setfilteredFeed] = useState(null);
  const [filter, setFilter] = useState("");
  const [labels, setLabels] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const listPerPage = 10;

  useEffect(() => {
    const getData = async () => {
      let results = await supabase
        .from("tbl_locked_artist_ids_stub_v2")
        .select(
          `rtsvrf_artist_id, artist_name, artist_label_connection, personality_type, artist_spotfy_id, rtsvrf_artist_id, artist_news_link, artist_genre`
        )
        .order("artist_name", { ascending: true });
      setFeed(results.data);
      setTotal(results.data.length);
      const labelList = await getArtistLabels();
      setLabels(labelList);
    };
    getData();
  }, []);

  useEffect(() => {
    async function fetchPaginationData() {
      let data = feed;
      if (feedFilter === "Feed") {
        data = data?.filter((item) => item.artist_news_link);
      } else if (feedFilter === "No feed") {
        data = data?.filter((item) => !item.artist_news_link);
      }
      if (personalityFilter === "") {
        data = data?.filter((item) => !item.personality_type);
      } else if (personalityFilter === "Others") {
        console.log(personalityFilter, data);
        data = data?.filter((item) => item.personality_type);
        console.log(personalityFilter, data);
      }
      if (labelFilter) {
        data = data?.filter((item) =>
          item.artist_label_connection?.includes(labelFilter)
        );
      }
      if (filter) {
        data = data?.filter((item) =>
          item?.artist_name?.toLowerCase().includes(filter.toLowerCase())
        );
      }
      setTotal(data?.length);
      data = data?.slice(
        (currentPage - 1) * listPerPage,
        currentPage * listPerPage
      );
      setfilteredFeed(data);
    }
    fetchPaginationData();
  }, [currentPage, feed, filter, feedFilter, personalityFilter, labelFilter]);

  return (
    <div className="sm:m-2 m-4">
      <NewsFilter
        feed={feed}
        feedFilter={feedFilter}
        setFeedFilter={setFeedFilter}
        setFilter={setFilter}
        setCurrentPage={setCurrentPage}
        personalityFilter={personalityFilter}
        setPersonalityFilter={setPersonalityFilter}
        labelFilter={labelFilter}
        setLabelFilter={setLabelFilter}
        labels={labels}
      />
      <NewsFeedTable feed={filteredFeed} />
      <Pagination
        listPerPage={listPerPage}
        total={total}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ArtistList;
