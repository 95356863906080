import React, { useEffect, useRef, useState } from "react";
import {
  HUBSPOT_FORM,
  NEWS_LINK,
  modalCustomStyles,
} from "../rtsvrf-utils/Constants";
import Modal from "react-modal";
import share from "../rtsvrf-assets/icons/sharing.png";
import CloseIcon from "../rtsvrf-assets/icons/close.png";
import YouTube from "react-youtube";
import website from "../rtsvrf-assets/icons/web.png";
import twitter from "../rtsvrf-assets/icons/x.png";
import facebook from "../rtsvrf-assets/icons/facebook.png";
import instagram from "../rtsvrf-assets/icons/instagram.png";
import youtube from "../rtsvrf-assets/icons/youtube.png";
import tiktok from "../rtsvrf-assets/icons/tiktok.png";
import spotify from "../rtsvrf-assets/icons/spotify.png";
import apple from "../rtsvrf-assets/icons/apple.png";
import deezer from "../rtsvrf-assets/icons/deezer-transparent.png";
import amazon from "../rtsvrf-assets/icons/amazon-transparent.png";
import tickets from "../rtsvrf-assets/icons/tickets.png";
import news from "../rtsvrf-assets/icons/news.png";
import threads from "../rtsvrf-assets/icons/threads.png";
import snapchat from "../rtsvrf-assets/icons/snapchat.png";
import mastodon from "../rtsvrf-assets/icons/mastodon.png";
import bluesky from "../rtsvrf-assets/icons/bluesky.png";
import signal from "../rtsvrf-assets/icons/signal.png";
import whatsapp from "../rtsvrf-assets/icons/whatsapp.png";
import telegram from "../rtsvrf-assets/icons/telegram.png";
import discord from "../rtsvrf-assets/icons/discord.png";
import twitch from "../rtsvrf-assets/icons/twitch.png";
import vimeo from "../rtsvrf-assets/icons/vimeo.png";
import tidal from "../rtsvrf-assets/icons/tidal.png";
import audius from "../rtsvrf-assets/icons/audius.png";
import sound from "../rtsvrf-assets/icons/sound.png";
import soundcloud from "../rtsvrf-assets/icons/soundcloud.png";
import merch from "../rtsvrf-assets/icons/merchtrolley-transparent.png";
import disabledmerch from "../rtsvrf-assets/icons/merch_disabled.png";
import disabledtwitch from "../rtsvrf-assets/icons/twitch_disabled.png";
import disabledtidal from "../rtsvrf-assets/icons/tidal_disabled.png";
import disabledaudius from "../rtsvrf-assets/icons/audius_disabled.png";
import disabledsound from "../rtsvrf-assets/icons/sound_disabled.png";
import disabledsoundcloud from "../rtsvrf-assets/icons/soundcloud_disabled.png";
import disabledvimeo from "../rtsvrf-assets/icons/vimeo_disabled.png";
import disabledwebsite from "../rtsvrf-assets/icons/web_disabled.png";
import disabledtwitter from "../rtsvrf-assets/icons/x_disabled.png";
import disabledfacebook from "../rtsvrf-assets/icons/facebook_disabled.png";
import disabledinstagram from "../rtsvrf-assets/icons/instagram_disabled.png";
import disabledyoutube from "../rtsvrf-assets/icons/youtube_disabled.png";
import disabledtiktok from "../rtsvrf-assets/icons/tiktok_disabled.png";
import disabledspotify from "../rtsvrf-assets/icons/spotify_disabled.png";
import disabledapple from "../rtsvrf-assets/icons/apple_disabled.png";
import disableddeezer from "../rtsvrf-assets/icons/deezer_disabled.png";
import disabledamazon from "../rtsvrf-assets/icons/amazon-disabled.png";
import disabledtickets from "../rtsvrf-assets/icons/diabled-tickets.png";
import disablednews from "../rtsvrf-assets/icons/news_disabled.png";
import disabledthreads from "../rtsvrf-assets/icons/threads_disabled.png";
import disabledsnapchat from "../rtsvrf-assets/icons/snapchat_disabled.png";
import disabledmastodon from "../rtsvrf-assets/icons/mastodon_disabled.png";
import disabledbluesky from "../rtsvrf-assets/icons/bluesky_disabled.png";
import disabledsignal from "../rtsvrf-assets/icons/signal_disabled.png";
import disabledwhatsapp from "../rtsvrf-assets/icons/whatsapp_disabled.png";
import disabledtelegram from "../rtsvrf-assets/icons/telegram_disabled.png";
import disableddiscord from "../rtsvrf-assets/icons/discord_disabled.png";
import community from "../rtsvrf-assets/icons/community.png";
import disabledcommunity from "../rtsvrf-assets/icons/disabled-community.png";
import verified from "../rtsvrf-assets/icons/av-tick.png";
import disabledspotlight from "../rtsvrf-assets/icons/disabled-show.png";
import spotlight from "../rtsvrf-assets/icons/show.png";
import google from "../rtsvrf-assets/icons/play_store.png";
import appleStore from "../rtsvrf-assets/icons/apple_store.svg";
import claim from "../rtsvrf-assets/icons/grab.png";
import defaultImage from "../rtsvrf-assets/icons/account.png";
import CopyToClipboard from "react-copy-to-clipboard";

Modal.setAppElement("#root");
const ProfileV1 = ({
  artist,
  loading,
  platformLiveLinks,
  fieldsStatus,
  artistImage,
  isFieldStatusAvailable,
  expand,
  setExpand,
  id,
  goBack,
  preferences,
  slug,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const url = window.location.origin + window.location.pathname;
  return (
    <>
      <div className="flex sm:flex-col px-4">
        <div className="flex flex-col justify-start items-center my-8">
          <div className="flex flex-col justify-start items-end">
            <img
              className={`${
                artistImage ? "w-80 h-80" : "w-48 h-48"
              } relative rounded-lg`}
              src={artistImage || defaultImage}
              alt="profile-img"
            />
            <button
              onClick={() => {
                setIsOpen(true);
              }}
              className="absolute m-2 sm:mx-0 sm:my-2"
              style={{
                zIndex: 1,
              }}
            >
              <img className="w-10 h-10" src={share} alt="icon" />
            </button>
          </div>
          <h1 className="text-4xl flex mt-3 font-bold text-white">
            {artist?.artist_name}
            {artist?.rtsvrf_profile_claim_status ? (
              <span>
                <img width={"20"} height={"20"} alt="verified" src={verified} />
              </span>
            ) : (
              <span
                className="cursor-pointer"
                onClick={() => (window.location.href = HUBSPOT_FORM)}
              >
                <img width={"20"} height={"20"} alt="claim" src={claim} />
              </span>
            )}
          </h1>
          {artist?.artist_bio_text && (
            <div className="max-w-sm text-center">
              <h2 className="text-2xl mt-2 font-semibold text-white">Bio</h2>
              <div class="group flex relative">
                <span
                  onClick={() => setExpand(!expand)}
                  class="w-80 sm:p-4 text-left cursor-pointer text-md font-light text-white"
                >
                  {expand ? (
                    <>{artist.artist_bio_text} </>
                  ) : (
                    <>
                      {artist.artist_bio_text?.slice(0, 120)}
                      {artist.artist_bio_text?.length > 120 && (
                        <span className="font-bold">...more</span>
                      )}
                    </>
                  )}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-wrap ml-auto mr-auto justify-around items-start my-4">
          <div className="m-4 flex flex-col items-center sm:m-1">
            <button
              disabled={
                !artist?.artist_website_link ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.website_link_status === false)
              }
              onClick={() =>
                artist?.artist_website_link &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.website_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = artist?.artist_website_link)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_website_link &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.website_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? website
                    : disabledwebsite
                }
                alt="icon"
              />
            </button>

            <button
              disabled={
                !artist?.rtsvrf_artist_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.artist_news_link_status === false)
              }
              onClick={() =>
                artist?.rtsvrf_artist_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.artist_news_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `${NEWS_LINK}${artist?.rtsvrf_artist_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.rtsvrf_artist_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.artist_news_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? news
                    : disablednews
                }
                alt="icon"
              />
            </button>
          </div>

          <div className="m-4 flex flex-col items-center sm:m-1">
            <button
              disabled={
                !artist?.artist_twitter_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.twitter_link_status === false)
              }
              onClick={() =>
                artist?.artist_twitter_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.twitter_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://twitter.com/${artist?.artist_twitter_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_twitter_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.twitter_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? twitter
                    : disabledtwitter
                }
                alt="icon"
              />
            </button>

            <button
              disabled={
                !artist?.artist_facebk_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.facebk_link_status === false)
              }
              onClick={() =>
                artist?.artist_facebk_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.facebk_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://www.facebook.com/${artist?.artist_facebk_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_facebk_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.facebk_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? facebook
                    : disabledfacebook
                }
                alt="icon"
              />
            </button>

            <button
              disabled={
                !artist?.artist_instag_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.instag_link_status === false)
              }
              onClick={() =>
                artist?.artist_instag_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.instag_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://instagram.com/${artist?.artist_instag_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_instag_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.instag_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? instagram
                    : disabledinstagram
                }
                alt="icon"
              />
            </button>

            <button
              disabled={
                !artist?.artist_youtub_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.youtube_link_status === false)
              }
              onClick={() =>
                artist?.artist_youtub_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.youtube_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://www.youtube.com/user/${artist?.artist_youtub_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_youtub_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.youtube_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? youtube
                    : disabledyoutube
                }
                alt="icon"
              />
            </button>

            <button
              disabled={
                !artist?.artist_tiktok_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.tiktok_link_status === false)
              }
              onClick={() =>
                artist?.artist_tiktok_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.tiktok_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://www.tiktok.com/@${artist?.artist_tiktok_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_tiktok_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.tiktok_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? tiktok
                    : disabledtiktok
                }
                alt="icon"
              />
            </button>
          </div>

          <div className="m-4 flex flex-col items-center sm:m-1">
            <button
              disabled={
                !artist?.artist_threads_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.threads_link_status === false) ||
                !platformLiveLinks?.artist_threads_id
              }
              onClick={() =>
                artist?.artist_threads_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.threads_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = platformLiveLinks?.artist_threads_id)
              }
              className="mx-2 my-1 sm:mx-0 sm:mt-1 mb-2"
            >
              <img
                className="w-[75px] h-[54px] sm:w-12 sm:h-[36px]"
                src={
                  artist?.artist_threads_id &&
                  platformLiveLinks?.artist_threads_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.threads_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? threads
                    : disabledthreads
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_snapchat_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.snapchat_link_status === false) ||
                !platformLiveLinks?.artist_snapchat_id
              }
              onClick={() =>
                artist?.artist_snapchat_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.snapchat_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = platformLiveLinks?.artist_snapchat_id)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_snapchat_id &&
                  platformLiveLinks?.artist_snapchat_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.snapchat_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? snapchat
                    : disabledsnapchat
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_discord_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.discord_link_status === false) ||
                !platformLiveLinks?.artist_discord_id
              }
              onClick={() =>
                artist?.artist_discord_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.discord_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = platformLiveLinks?.artist_discord_id)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_discord_id &&
                  platformLiveLinks?.artist_discord_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.discord_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? discord
                    : disableddiscord
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_mastodon_id ||
                !platformLiveLinks?.artist_mastodon_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.mastodon_link_status === false)
              }
              onClick={() =>
                artist?.artist_mastodon_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.mastodon_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = platformLiveLinks?.artist_mastodon_id)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_mastodon_id &&
                  platformLiveLinks?.artist_mastodon_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.mastodon_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? mastodon
                    : disabledmastodon
                }
                alt="icon"
              />
            </button>
          </div>

          <div className="m-4 flex flex-col items-center sm:m-1">
            <button
              disabled={
                !artist?.artist_bluesky_id ||
                !platformLiveLinks?.artist_bluesky_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.bluesky_link_status === false)
              }
              onClick={() =>
                artist?.artist_bluesky_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.bluesky_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = platformLiveLinks?.artist_bluesky_id)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_bluesky_id &&
                  platformLiveLinks?.artist_bluesky_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.bluesky_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? bluesky
                    : disabledbluesky
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_signal_id ||
                !platformLiveLinks?.artist_signal_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.signal_link_status === false)
              }
              onClick={() =>
                artist?.artist_signal_id &&
                platformLiveLinks?.artist_signal_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.signal_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = artist?.artist_signal_id)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_signal_id &&
                  platformLiveLinks?.artist_signal_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.signal_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? signal
                    : disabledsignal
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_whatsapp_id ||
                !platformLiveLinks?.artist_whatsapp_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.whatsapp_link_status === false)
              }
              onClick={() =>
                artist?.artist_whatsapp_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.whatsapp_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = platformLiveLinks?.artist_whatsapp_id)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_whatsapp_id &&
                  platformLiveLinks?.artist_whatsapp_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.whatsapp_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? whatsapp
                    : disabledwhatsapp
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_telegram_id ||
                !platformLiveLinks?.artist_telegram_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.telegram_link_status === false)
              }
              onClick={() =>
                artist?.artist_telegram_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.telegram_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = platformLiveLinks?.artist_telegram_id)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_telegram_id &&
                  platformLiveLinks?.artist_telegram_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.telegram_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? telegram
                    : disabledtelegram
                }
                alt="icon"
              />
            </button>
          </div>
          <div className="m-4 flex flex-col items-center sm:m-1">
            <button
              disabled={
                !artist?.artist_vimeo_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.vimeo_link_status === false)
              }
              onClick={() =>
                artist?.artist_vimeo_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.vimeo_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://vimeo.com/${artist?.artist_vimeo_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_vimeo_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.vimeo_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? vimeo
                    : disabledvimeo
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_tidal_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.tidal_link_status === false)
              }
              onClick={() =>
                artist?.artist_tidal_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.tidal_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://tidal.com/browse/artist/${artist?.artist_tidal_id}`)
              }
              className="m-1/2 sm:mx-0 sm:my-1/2"
            >
              <img
                className="w-16 h-16 sm:w-11 sm:h-11"
                src={
                  artist?.artist_tidal_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.tidal_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? tidal
                    : disabledtidal
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_audius_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.audius_link_status === false)
              }
              onClick={() =>
                artist?.artist_audius_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.audius_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://audius.co/${artist?.artist_audius_id}`)
              }
              className="m-2 sm:mx-0 sm:my-1"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_audius_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.audius_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? audius
                    : disabledaudius
                }
                style={
                  artist?.artist_audius_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.audius_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? { borderRadius: "50%", border: "5px solid black" }
                    : { borderRadius: "50%", border: "5px solid #A39E9E" }
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_sound_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.sound_link_status === false)
              }
              onClick={() =>
                artist?.artist_sound_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.sound_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://www.sound.xyz/${artist?.artist_sound_id}`)
              }
              className="m-1 sm:mx-0 sm:my-2"
            >
              <img
                className="w-[100px] h-[60px] sm:w-16 sm:h-[40px]"
                src={
                  artist?.artist_sound_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.sound_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? sound
                    : disabledsound
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_sound_cloud_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.sound_cloud_link_status === false)
              }
              onClick={() =>
                artist?.artist_sound_cloud_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.sound_cloud_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://soundcloud.com/${artist?.artist_sound_cloud_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_sound_cloud_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.sound_cloud_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? soundcloud
                    : disabledsoundcloud
                }
                alt="icon"
              />
            </button>
          </div>
          <div className="m-4 flex flex-col items-center sm:m-1">
            <button
              disabled={
                !artist?.artist_spotfy_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.spotify_link_status === false)
              }
              onClick={() =>
                artist?.artist_spotfy_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.spotify_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://open.spotify.com/artist/${artist?.artist_spotfy_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_spotfy_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.spotify_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? spotify
                    : disabledspotify
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_aplmus_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.aplmus_link_status === false)
              }
              onClick={() =>
                artist?.artist_aplmus_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.aplmus_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://music.apple.com/us/artist/${artist?.artist_aplmus_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_aplmus_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.aplmus_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? apple
                    : disabledapple
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                !artist?.artist_twitch_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.twitch_link_status === false)
              }
              onClick={() =>
                artist?.artist_twitch_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.twitch_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://www.twitch.tv/${artist?.artist_twitch_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_twitch_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.twitch_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? twitch
                    : disabledtwitch
                }
                alt="icon"
              />
            </button>

            <button
              disabled={
                !artist?.artist_deezer_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.deezer_link_status === false)
              }
              onClick={() =>
                artist?.artist_deezer_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.deezer_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://www.deezer.com/en/artist/${artist?.artist_deezer_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_deezer_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.deezer_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? deezer
                    : disableddeezer
                }
                alt="icon"
              />
            </button>

            <button
              disabled={
                !artist?.artist_amazon_id ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.amazon_link_status === false)
              }
              onClick={() =>
                artist?.artist_amazon_id &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.amazon_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href = `https://www.amazon.com/music/player/artists/${artist?.artist_amazon_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  artist?.artist_amazon_id &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.amazon_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? amazon
                    : disabledamazon
                }
                alt="icon"
              />
            </button>
          </div>

          <div className="m-4 flex flex-col items-center sm:m-1">
            <button
              disabled={
                (!artist?.artist_bandsit_id &&
                  !preferences.artist_platform_preference_tickets &&
                  !artist?.artist_other_tickets_platform_link) ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.tickets_platform_link_status === false)
              }
              onClick={() =>
                (artist?.artist_bandsit_id ||
                  preferences.artist_platform_preference_tickets ||
                  artist?.artist_other_tickets_platform_link) &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.tickets_platform_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href =
                  preferences.artist_platform_preference_tickets ||
                  artist?.artist_other_tickets_platform_link ||
                  `https://www.bandsintown.com/a/${artist?.artist_bandsit_id}`)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  (artist?.artist_bandsit_id ||
                    preferences.artist_platform_preference_tickets ||
                    artist?.artist_other_tickets_platform_link) &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.tickets_platform_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? tickets
                    : disabledtickets
                }
                alt="icon"
              />
            </button>

            <button
              disabled={
                (!artist?.artist_mrchbr_id &&
                  !preferences.artist_platform_preference_merch &&
                  !artist?.artist_other_merch_platform_link) ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.merchandise_platform_link_status === false)
              }
              onClick={() =>
                (artist?.artist_mrchbr_id ||
                  preferences.artist_platform_preference_merch ||
                  artist?.artist_other_merch_platform_link) &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.merchandise_platform_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href =
                  preferences.artist_platform_preference_merch ||
                  artist?.artist_other_merch_platform_link ||
                  artist?.artist_mrchbr_id)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  (artist?.artist_mrchbr_id ||
                    preferences.artist_platform_preference_merch ||
                    artist?.artist_other_merch_platform_link) &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.merchandise_platform_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? merch
                    : disabledmerch
                }
                alt="icon"
              />
            </button>
            <button
              disabled={
                (!preferences?.artist_platform_preference_shows &&
                  !artist?.artist_other_shows_platform_link) ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.shows_platform_link_status === false)
              }
              onClick={() =>
                (preferences?.artist_platform_preference_shows ||
                  artist?.artist_other_shows_platform_link) &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.shows_platform_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href =
                  preferences?.artist_platform_preference_shows ||
                  artist?.artist_other_shows_platform_link)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  preferences?.artist_platform_preference_shows ||
                  artist?.artist_other_shows_platform_link
                    ? spotlight
                    : disabledspotlight
                }
                alt="icon"
              />
            </button>

            <button
              disabled={
                (!preferences?.artist_platform_preference_community &&
                  !artist?.artist_other_community_platform_link &&
                  !artist?.artist_discrd_link &&
                  !artist?.artist_patren_id) ||
                (isFieldStatusAvailable &&
                  fieldsStatus?.community_platform_link_status === false)
              }
              onClick={() =>
                (preferences?.artist_platform_preference_community ||
                  artist?.artist_other_community_platform_link ||
                  artist?.artist_discrd_link ||
                  artist?.artist_patren_id) &&
                ((isFieldStatusAvailable &&
                  fieldsStatus?.community_platform_link_status !== false) ||
                  !isFieldStatusAvailable) &&
                (window.location.href =
                  preferences?.artist_platform_preference_community ||
                  artist?.artist_other_community_platform_link ||
                  artist?.artist_discrd_link ||
                  artist?.artist_patren_id)
              }
              className="m-2 sm:mx-0 sm:my-2"
            >
              <img
                className="w-12 h-12 sm:w-8 sm:h-8"
                src={
                  (preferences?.artist_platform_preference_community ||
                    artist?.artist_other_community_platform_link ||
                    artist?.artist_discrd_link ||
                    artist?.artist_patren_id) &&
                  ((isFieldStatusAvailable &&
                    fieldsStatus?.community_platform_link_status !== false) ||
                    !isFieldStatusAvailable)
                    ? community
                    : disabledcommunity
                }
                alt="icon"
              />
            </button>
          </div>
        </div>
        <div className="m-4 flex flex-col items-center sm:m-1 ml-12">
          <button className="m-2 cursor-pointer sm:mx-0 sm:my-2">
            <img
              className="cursor-pointer"
              width={"120"}
              height={"60"}
              src={google}
              alt="icon"
            />
          </button>

          <button className="m-2 cursor-pointer sm:mx-0 sm:my-2">
            <img
              className="cursor-pointer"
              width={"120"}
              height={"60"}
              src={appleStore}
              alt="icon"
            />
          </button>
        </div>
      </div>

      <div className="flex flex-wrap justify-start items-center m-4">
        {artist?.artist_spotfy_id &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.spotify_link_status !== false)) && (
            <span className="m-4">
              <iframe
                className="rounded-md"
                title="spotify"
                src={`https://open.spotify.com/embed/artist/${artist?.artist_spotfy_id}?utm_source=generator`}
                width="100%"
                height="400"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </span>
          )}
        {artist?.artist_aplmus_default_playlist &&
          artist?.artist_aplmus_default_target_type &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.aplmus_link_status !== false)) && (
            <span className="m-4">
              <iframe
                allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                frameborder="0"
                width="100%"
                className="rounded-md"
                height="400"
                title="apple"
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                src={`https://embed.music.apple.com/us/${artist?.artist_aplmus_default_target_type}/${artist?.artist_aplmus_default_playlist}`}
              ></iframe>
            </span>
          )}
        {artist?.artist_deezer_id &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.deezer_link_status !== false)) && (
            <span className="m-4">
              <iframe
                title="deezer-widget"
                src={`https://widget.deezer.com/widget/dark/artist/${artist?.artist_deezer_id}/top_tracks`}
                width="100%"
                height="400"
                className="rounded-md"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media; clipboard-write"
              ></iframe>
            </span>
          )}
        {artist?.artist_amazon_id &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.amazon_link_status !== false)) && (
            <span className="m-4">
              <iframe
                title="amazon"
                id={`AmazonMusicEmbed${artist?.artist_amazon_id}`}
                src={`https://music.amazon.com/embed/${artist?.artist_amazon_id}`}
                width="100%"
                height="400"
                className="rounded-md"
              ></iframe>
            </span>
          )}
        {artist?.artist_twitch_default_video_chanel &&
          artist?.artist_twitch_default_video_type &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.twitch_link_status !== false)) && (
            <span className="m-4">
              <iframe
                title="twitch"
                src={`https://player.twitch.tv/?${artist.artist_twitch_default_video_type}=${artist?.artist_twitch_default_video_chanel}&parent=${window.location.host}&muted=true`}
                width="100%"
                height="400"
                className="rounded-md"
                allowfullscreen
              ></iframe>
            </span>
          )}
        {artist?.artist_vimeo_default_video &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.vimeo_link_status !== false)) && (
            <span className="m-4">
              <iframe
                title="Vimeo Video Player"
                src={`https://player.vimeo.com/video/${artist?.artist_vimeo_default_video}`}
                width="100%"
                height="400"
                className="rounded-md"
                muted
              />
            </span>
          )}

        {artist?.artist_sound_cloud_default_video &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.sound_cloud_link_status !== false)) && (
            <span className="m-4">
              <iframe
                width="100%"
                className="rounded-lg"
                title="soundcloud"
                height="400"
                frameborder="no"
                src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${artist?.artist_sound_cloud_default_video}&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true`}
              ></iframe>
            </span>
          )}

        {artist?.artist_tiktok_id &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.tiktok_link_status !== false)) && (
            <span className="m-4">
              <iframe
                src={`https://www.tiktok.com/embed/@${artist?.artist_tiktok_id}`}
                allowfullscreen
                title="tiktok"
                scrolling="no"
                width="100%"
                height="400"
                className="rounded-md"
                allow="encrypted-media;"
              ></iframe>
            </span>
          )}
        {artist?.artist_youtube_default_video &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.youtube_link_status !== false)) && (
            <span className="m-4">
              <YouTube
                videoId={artist?.artist_youtube_default_video}
                opts={{
                  height: "400",
                  width: "100%",
                  playerVars: {
                    autoplay: 1,
                  },
                }}
                iframeClassName="rounded-md"
                onReady={(e) => e.target.pauseVideo()}
              />
            </span>
          )}
      </div>
      <div>
        {slug &&
          (!isFieldStatusAvailable ||
            (isFieldStatusAvailable &&
              fieldsStatus?.artist_news_link_status !== false)) && (
            <div className="py-6">
              <iframe
                width="900"
                height={"600"}
                className="rounded-md lg:w-full"
                title="artist feed"
                src={`https://rss.app/embed/v1/list/${slug}`}
                frameborder="0"
              ></iframe>
            </div>
          )}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={modalCustomStyles}
        contentLabel="Profile Image Modal"
      >
        <div className="flex justify-between">
          <h2 className="text-black text-lg">Update profile picture</h2>
          <span onClick={() => setIsOpen(false)} className="cursor-pointer">
            <img src={CloseIcon} alt="close" width={"15"} height={"15"} />
          </span>
        </div>
        <div className="flex flex-col p-1 w-full text-black items-center">
          <input
            className="w-full p-2 border-black rounded-lg border-2"
            value={url}
            disabled
          />

          <CopyToClipboard text={url} onCopy={() => setIsOpen(false)}>
            <button className="mt-2 p-3 text-black bg-yellow-400 rounded-lg">
              Copy to clipboard
            </button>
          </CopyToClipboard>
        </div>
      </Modal>
    </>
  );
};

export default ProfileV1;
