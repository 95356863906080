export const NEWS_LINK = "https://news.rtsvrf.com/news/";
export const HUBSPOT_FORM =
  "https://share.hsforms.com/1tMnLZSTLTiS892R0hvfkgwdyhum";

export const modalCustomStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.85)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
    padding: "12px",
    maxWidth: "400px",
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
};
